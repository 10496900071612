
import { Component, Vue } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import Container from '~/components/Container.vue'
import SearchForm from '~/components/search-form/SearchForm.vue'
import Questions from '~/components/info-block/Questions.vue'
import Reviews from '~/components/info-block/Reviews.vue'
import Articles from '~/components/info-block/Articles.vue'
import BannerNew from '~/components/BannerNew.vue'
import FaqGibdd from '~/components/info-block/gibdd/FaqGibdd.vue'
import FaqFssp from '~/components/info-block/fssp/FaqFssp.vue'
import FaqFns from '~/components/info-block/fns/FaqFns.vue'
import BannerNewApp from '~/components/BannerNewApp.vue'
import ScrollToTopButton from '~/components/UI/ScrollToTopButton.vue'

@Component({
  components: {
  BannerNew,
  Articles,
  Reviews,
  Container,
  Questions,
  LazyHydrate,
  SearchForm,
  FaqGibdd,
  FaqFssp,
  FaqFns,
  BannerNewApp,
  ScrollToTopButton
  }
  })
export default class extends Vue {
  head () {
    let title
    let meta: Record<string, string>[]
    let link: Record<string, string>[]

    switch (this.$route.path) {
      case '/gibdd':
        title = 'Проверка и оплата штрафов ГИБДД, судебных приставов, по налогам онлайн со скидкой'
        meta = [{
          hid: 'description',
          name: 'description',
          content: 'На сайте сервиса онлайн оплаты государственных задолженностей можно мгновенно оплатить штрафы ГИБДД, судебных приставов, по налогам онлайн со скидкой 25%'
        }]
        link = [
          { hid: 'canonical', rel: 'canonical', href: 'https://gos-oplata.ru/gibdd' },
          { rel: 'prefetch', as: 'image', href: require('@/assets/img/before-leave/gibdd.jpg') }
        ]
        break
      case '/fns':
        title = 'Узнать задолженность по налогам бесплатно и оплатить онлайн на сайте'
        meta = [{
          hid: 'description',
          name: 'description',
          content: 'Проверить налоговую задолженность можно бесплатно онлайн по ИНН физического или юридического лица, по фамилии (данным паспорта), индексу документа (УИН). Оплатить можно на сайте Гос Оплата в пару кликов, гарантия, квитанция на почту'
        }]
        link = [
          { hid: 'canonical', rel: 'canonical', href: 'https://gos-oplata.ru/fns' },
          { rel: 'prefetch', as: 'image', href: require('@/assets/img/before-leave/fns.jpg') }
        ]
        break
      case '/fssp':
        title = 'Узнать задолженность у судебных приставов по фамилии онлайн'
        meta = [{
          hid: 'description',
          name: 'description',
          content: 'Сайт проверки и оплаты задолженностей судебным приставам. Покажем все имеющиеся долги в базе ФССП, поиск по фамилии и региону. Оплата задолженности приставам без регистрации любой картой'
        }]
        link = [
          { hid: 'canonical', rel: 'canonical', href: 'https://gos-oplata.ru/fssp' },
          { rel: 'prefetch', as: 'image', href: require('@/assets/img/before-leave/fssp.jpg') }
        ]
        break
      default:
        title = 'Проверка и оплата штрафов ГИБДД, судебных приставов, по налогам онлайн со скидкой'
        meta = [{
          hid: 'description',
          name: 'description',
          content: 'На сайте сервиса онлайн оплаты государственных задолженностей можно мгновенно оплатить штрафы ГИБДД, судебных приставов, по налогам онлайн со скидкой 25%'
        }]
        link = [
          { hid: 'canonical', rel: 'canonical', href: 'https://gos-oplata.ru/' },
          { rel: 'prefetch', as: 'image', href: require('@/assets/img/before-leave/fns.jpg') }
        ]
        break
    }
    return {
      title,
      meta,
      link
    }
  }

  mounted () {
    if (this.$route.query.email) {
      this.$axios.patch(process.env.API_ENDPOINT + 'user', { email: this.$route.query.email })
    }

    window.addEventListener('load', () => {
      this.$axios.get(process.env.API_ENDPOINT + 'user/event', {
        params: Object.assign(this.$ga.getStorage(), {
          event_action: 'main',
          accrual_type_id: this.$route.params.id || 'fns'
        })
      })
    }, { once: true })
    // Получаем вопросы
    this.$store.dispatch('questions/setList')
  }

  /**
   * получаем сведения о последнем действии пользователя
   */
  lastActivity (): void {
    if (this.$route.name === 'index') {
      this.$lastActivity.getData().then((storageData) => {
        if (typeof storageData === 'boolean') {
          return
        }

        if (storageData.type && this.$route.params.id !== storageData.type) {
          this.$router.replace('/' + storageData.type)
        }
      })
    }
  }
}
