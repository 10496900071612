
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import qs from 'qs'
import LazyHydrate from 'vue-lazy-hydration'
import DocWidgetList from '@pp-frontend/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import TabHeader from './TabHeader.vue'
import SearchFormGibdd from '~/components/search-form/SearchFormGibdd.vue'
import SearchFormFns from '~/components/search-form/SearchFormFns.vue'
import SearchFormFssp from '~/components/search-form/SearchFormFssp.vue'

@Component({
  components: {
  LazyHydrate,
  SearchFormGibdd,
  SearchFormFssp,
  SearchFormFns,
  TabHeader,
  DocWidgetList
  }
  })
export default class extends Vue {
  @Prop({ type: Boolean, default: true }) changeRoute!: boolean
  @Prop({ type: Boolean, default: true }) showMeta!: boolean
  @Prop() isIframe!: boolean

  activeForm: number = 0
  selector: boolean = false

  tabs = [
    { id: 'fssp', img: 'trial', title: 'Судебные приставы', component: 'SearchFormFssp' },
    { id: 'gibdd', img: 'gibdd', title: 'Штрафы ГИБДД', component: 'SearchFormGibdd' },
    { id: 'fns', img: 'fns', title: 'Проверить налоги', component: 'SearchFormFns' }
  ]

  get searchType () {
    return this.tabs[this.activeForm].id
  }

  tabClick (id: string) {
    if (!this.changeRoute) {
      this.activateByRoute(id)
      return
    }
    const path = `/${id}${Object.keys(this.$route.query).length ? '?' + qs.stringify(this.$route.query) : ''}`
    if (this.$route.fullPath !== path) {
      this.$router.replace(path)
    }
  }

  isActive (num: number) {
    return this.activeForm === num
  }

  created () {
    this.activateByRoute(this.$route.path)
  }

  activateByRoute (id: string) {
    const index = this.tabs.findIndex(tab => tab.id === id || `/${tab.id}` === id)
    this.activeForm = index !== -1 ? index : 1
  }

  get title () {
    const titles = {
      '/fssp': 'Проверка и оплата задолженностей ФССП',
      '/gibdd': 'Проверка и оплата штрафов ГИБДД',
      '/fns': 'Проверка и оплата налоговой задолженности физических лиц'
    }
    return titles[this.$route.path] || 'Проверка и оплата штрафов ГИБДД'
  }

  get description () {
    const descriptions = {
      '/fssp': 'Для проверки задолженностей ФССП введите ФИО, дату рождения и регион',
      '/gibdd': 'Для проверки штрафов ГИБДД введите номер СТС и гос. номер автомобиля, или номер водительского удостоверения, или же номер постановления',
      '/fns': 'Для проверки налоговых задолженностей достаточно ввести свой ИНН или паспортные данные'
    }
    return descriptions[this.$route.path] || 'Для проверки штрафов ГИБДД введите номер СТС и гос. номер автомобиля, или номер водительского удостоверения, или же номер постановления'
  }
}
